import React from 'react'
import { Link } from 'gatsby'
import { connect } from 'react-redux'

import CartIcon from './carticon.jsx'

import SidebarMenu from './sidebarMenu'
import MiniCart from './miniCart'
import ImageData from '../product/imageData'

import { Responsive, Menu } from 'semantic-ui-react'

import config from '../../config'

const css = {
  width: '40px',
  height: '6px',
  backgroundColor: config.colors.secondary,
  margin: '6px 0',
}

const Header = props => (
  <div>
    {/* <div style={{ margin: 0, position: 'relative' }}> */}
    <Responsive maxWidth={767}>
      <SidebarMenu />
      <Menu
        fixed="top"
        borderless
        style={{
          background: config.colors.tertiary,
          fontSize: '2rem',
          borderBottomStyle: 'solid',
          borderWidth: '6px',
          borderColor: config.colors.secondary,
          minHeight: '6vh',
          margin: '0 auto ',
        }}
      >
        <Menu.Item>
          <div
            style={{ display: 'block', borderRadius: '5px' }}
            onClick={() => {
              props.dispatch({ type: 'MENU_STATE' })
            }}
          >
            <div style={css} />
            <div style={css} />
            <div style={css} />
          </div>
        </Menu.Item>
        <Menu.Item style={{ paddingLeft: '0' }}>
          <Link style={{ color: config.colors.primary }} to="/#hatstore">
            <h1 style={{ fontSize: '2rem' }}>
              <span className="redunderline">MAHA</span>
            </h1>
          </Link>
        </Menu.Item>
        <Menu.Menu position="right">
          <Menu.Item>
            <Link to="/cart">
              <CartIcon aria-label="shopping cart" />
            </Link>
          </Menu.Item>
        </Menu.Menu>
      </Menu>
    </Responsive>
    {/* </div> */}

    {/* <div style={{ position: 'relative' }}> */}
    <Responsive minWidth={768}>
      <SidebarMenu />
      <Menu
        fixed="top"
        borderless
        style={{
          background: config.colors.tertiary,
          fontSize: '2rem',

          borderBottomStyle: 'solid',
          borderWidth: '6px',
          borderColor: config.colors.secondary,
        }}
      >
        <Menu.Item>
          <div
            style={{ display: 'block', borderRadius: '5px' }}
            onClick={() => {
              props.dispatch({ type: 'MENU_STATE' })
            }}
          >
            <div style={css} />
            <div style={css} />
            <div style={css} />
          </div>
        </Menu.Item>
        <Menu.Item style={{ padding: '5px 5px 5px 0' }}>
          <Link style={{ color: config.colors.primary }} to="/#hatstore">
            <h1 style={{ fontSize: '2.5rem' }}>
              Make America <span className="redunderline">HATS</span> Again!
            </h1>
          </Link>
        </Menu.Item>

        <Menu.Menu position="right">
          <Menu.Item>
            <Link to="/cart">
              <CartIcon />
            </Link>
          </Menu.Item>
          <ImageData>
            <MiniCart />
          </ImageData>
        </Menu.Menu>
      </Menu>
    </Responsive>
    {/* </div> */}
  </div>
)

//export default Header
const mapStateToProps = state => {
  return {
    menu: state.menu,
  }
}

export default connect(mapStateToProps)(Header)
