import React from 'react'
import { Link } from 'gatsby'
import { connect } from 'react-redux'
// import Categories from './categories'

import { Menu, Sidebar, Button, Container, Icon } from 'semantic-ui-react'

const SidebarMenu = props => {
  return (
    <Sidebar as={Menu} animation="overlay" visible={props.menu} vertical>
      <Menu borderless>
        <Menu.Menu position="right">
          <h1 style={{ fontSize: '1.1rem', margin: '10px 10px 10px 0px' }}>
            Make America <span className="redunderline">HATS</span> Again!
          </h1>
          <Button
            icon="cancel"
            inverted
            size="large"
            style={{ backgroundColor: '#BB133E' }}
            onClick={() => {
              props.dispatch({ type: 'MENU_STATE' })
            }}
          />
        </Menu.Menu>
      </Menu>

      <Menu.Item>
        <Link
          to="/"
          className="sidemenu"
          onClick={() => {
            props.dispatch({ type: 'MENU_STATE' })
          }}
        >
          Home
        </Link>
      </Menu.Item>
      <Menu.Item>
        <Link
          to="/#hatstore"
          className="sidemenu"
          onClick={() => {
            props.dispatch({ type: 'MENU_STATE' })
          }}
        >
          Store
        </Link>
      </Menu.Item>
      {/* <Menu.Item>
      <Categories/>
      </Menu.Item> */}
      <Menu.Item>
        <Link
          to="/contact-us"
          className="sidemenu"
          onClick={() => {
            props.dispatch({ type: 'MENU_STATE' })
          }}
        >
          Contact
        </Link>
      </Menu.Item>
      <Menu.Item>
        <Link
          to="/aboutus"
          className="sidemenu"
          onClick={() => {
            props.dispatch({ type: 'MENU_STATE' })
          }}
        >
          About
        </Link>
      </Menu.Item>
      <Container fluid>
        <Menu.Item>
          <Link to="/cart">
            <Button
              fluid
              style={{ backgroundColor: '#BB133E', color: 'white' }}
              onClick={() => {
                props.dispatch({ type: 'MENU_STATE' })
              }}
            >
              Cart
              {'  '}
              <Icon name="shopping cart" />
            </Button>
          </Link>
        </Menu.Item>
      </Container>
    </Sidebar>
  )
}

const mapStateToProps = state => {
  return {
    menu: state.menu,
  }
}

export default connect(mapStateToProps)(SidebarMenu)
