// Possible idea to make the site more configurable from one file. Would be useful for public API keys and user text
const data = require('./pages.json')
let hatnames = []
data.forEach(title => {
  hatnames.push(title.name)
})

// Site Address
const site = "https://www.makeamericahatsagain.com/"

// Zapier webhook for FB post automation
const fbPostZapier = 'https://hooks.zapier.com/hooks/catch/3491159/clmges/'

// Site colors
const colors = {
  primary: '#002147',
  secondary: '#BB133E',
  tertiary: '#FFFFFF',
  forth: '#00B5AD',
  fifth: '#d4f4dd',
}


const price = 2495
// Discount Options
const siteDiscountPercent = 0
const categoryPercentageDiscount = 0
const discountedCategory = 'xmas'


// string configuration
const shippingDescription = 'Please choose from the available Shipping Options:' // Shipping Page information
const checkoutModalSuccessHeader = 'Payment Succesful' // Payment Confirmatoin modal Headers
const checkoutModalFailureHeader = 'Payment Failure' // Payment Confirmatoin modal Headers
const confirmOrder = 'Confirm My Order' // Button Text

//Product Options

const hatColors = [
  { key: 'W', id: 'White', value: '0', text: 'White' },
  { key: 'B', id: 'Blue', value: '1', text: 'Blue' },
]


// config export object
const config = {
  site: site,
  hatnames: hatnames,
  price: price,
  shippingDescription: shippingDescription,
  checkoutModalSuccessHeader: checkoutModalSuccessHeader,
  checkoutModalFailureHeader: checkoutModalFailureHeader,
  confirmOrder: confirmOrder,
  colors: colors,
  hatColors: hatColors,
  siteDiscountPercent: siteDiscountPercent,
  categoryPercentageDiscount:  categoryPercentageDiscount,
  discountedCategory : discountedCategory,
  fbPostZapier: fbPostZapier,
}

export default config
