import React, { Component } from 'react'
import { connect } from 'react-redux'

class PicData extends Component {
  render() {
    let back = this.props.data.allImageSharp.edges.filter(hat => {
      let matcher = new RegExp('Back', 'g')
      return matcher.test(hat.node.fluid.originalName)
    })
    let rightSide = this.props.data.allImageSharp.edges.filter(hat => {
      let matcher = new RegExp('Right', 'g')
      return matcher.test(hat.node.fluid.originalName)
    })
    let leftSide = this.props.data.allImageSharp.edges.filter(hat => {
      let matcher = new RegExp('Left', 'g')
      return matcher.test(hat.node.fluid.originalName)
    })
    let hatImage = this.props.data.allImageSharp.edges.filter(hat => {
      let title =
        this.props.name.charAt(0).toUpperCase() +
        this.props.name.slice(1).toLowerCase()
      let matcher = new RegExp(title, 'g')
      return matcher.test(hat.node.fluid.originalName)
    })
    let images = {
      hatImage: hatImage,
      back: back,
      leftSide: leftSide,
      rightSide: rightSide
    }

    return (
      // this will pass an array of images to the child component
      <div>{React.cloneElement(this.props.children, {...this.props}, images={...images})}</div>
    )
  }
}

const mapStateToProps = state => {
  return {
    productColor: state.productColor,
  }
}

export default connect(mapStateToProps)(PicData)
