import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import 'semantic-ui-css/components/reset.min.css'
import 'semantic-ui-css/components/site.min.css'
import 'semantic-ui-css/components/popup.min.css'
import 'semantic-ui-css/components/container.min.css'
import 'semantic-ui-css/components/segment.min.css'
import 'semantic-ui-css/components/button.min.css'
import 'semantic-ui-css/components/checkbox.min.css'
import 'semantic-ui-css/components/grid.min.css'
import 'semantic-ui-css/components/header.min.css'
import 'semantic-ui-css/components/table.min.css'
import 'semantic-ui-css/components/form.min.css'
import 'semantic-ui-css/components/icon.min.css'
import 'semantic-ui-css/components/modal.min.css'
import 'semantic-ui-css/components/menu.min.css'
import 'semantic-ui-css/components/sidebar.min.css'
import 'semantic-ui-css/components/image.min.css'
import 'semantic-ui-css/components/step.min.css'
import 'semantic-ui-css/components/card.min.css'
import 'semantic-ui-css/components/dropdown.min.css'
import 'semantic-ui-css/components/item.min.css'
import 'semantic-ui-css/components/input.min.css'
import 'semantic-ui-css/components/label.min.css'
import 'semantic-ui-css/components/loader.min.css'
import 'semantic-ui-css/components/popup.min.css'
import 'semantic-ui-css/components/dimmer.min.css'
import 'semantic-ui-css/components/transition.min.css'
import './style.css'

import Header from '../components/navigation/header'
import Footer from '../components/navigation/footer'

const Layout = ({ data, children }) => (
  <div style={{ margin: '0' }}>
    <Helmet>
      <title>{data.site.siteMetadata.title}</title>
      <meta
        name="description"
        content="Make America Hats Again. Start a conversation and make a difference come 2020. American made and embroidered 6 panel unstructured caps."
      />
      <meta
        name="keywords"
        content="Hats, Made in America, Trump, Ecommerce, Democrats, 2020, gifts"
      />
      <meta
        name="google-site-verification"
        content="-NpMU4c-6sbYumGUMTQmcJA8gppl8769GvLU6y9lsj4"
      />
      <meta
        property="og:type"
        content="website"
      />
      <meta
        property="og:image"
        content={"http://www.makeamericahatsagain.com" + data.allImageSharp.edges[20].node.fluid.src}
      />
      <meta
        property="og:image:secure_url"
        content={"https://www.makeamericahatsagain.com" + data.allImageSharp.edges[20].node.fluid.src}
      />
      <meta property="og:site_name" content="Make America Hats Again!" />

      <meta property="og:url" content="http://www.makeamericahatsagain.com" />
      <meta
        property="og:secure_url"
        content="https://www.makeamericahatsagain.com"
      />
      <meta
        property="og:description"
        content="It's time to start a conversation about what we've lost."
      />
      <link
        href="https://fonts.googleapis.com/css?family=Merriweather|Open+Sans"
        rel="stylesheet"
      />
    </Helmet>

    <Header />

    <div
      style={{
        margin: '30px auto 0',
        maxWidth: 1200,
        minHeight: '82vh',
        padding: '0px',
      }}
    >
      {children}
    </div>

    <Footer style={{ margin: '0', width: '100vw' }} />
  </div>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

const LayoutWithQuery = props => (
  <StaticQuery
    query={graphql`
      query Product {
        site {
          siteMetadata {
            title
          }
        }
        allImageSharp {
          edges {
            node {
              fluid(traceSVG: { color: "#BB133E" }) {
                aspectRatio
                tracedSVG
                src
              }
            }
          }
        }
      }
    `}
    render={data => <Layout data={data} {...props} />}
  />
)

export default LayoutWithQuery
