// Cart icon sits in menubar and will update with the number of line items in the cart

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Icon, Label, Transition } from 'semantic-ui-react'

class CartIcon extends Component {
  toggleVisibility = () => {
    this.props.dispatch({
      type: 'UPDATE_STATE',
      item: 'cartAnimation',
      value: true,
    })
  }

  render() {
    if (this.props.cart.length > 0) {
      return (
        <div aria-label="shopping cart info">
          <Transition
            animation={'jiggle'}
            duration={750}
            visible={this.props.cartAnimation}
            onComplete={this.toggleVisibility}
          >
            <div>
              <Label horizontal style={{ color: 'white', backgroundColor: '#BB133E' }} circular>
                {this.props.cart.length}
              </Label>

              <Icon
                style={{ color: '#BB133E' }}
                size="large"
                name="shopping cart"
                aria-label="shopping cart icon"
              />
            </div>
          </Transition>
        </div>
      )
    } else {
      return (
        <div>
          <Transition
            animation={'jiggle'}
            duration={750}
            visible={this.props.cartAnimation}
            onComplete={this.toggleVisibility}
          >
            <Icon
              style={{ color: '#002147' }}
              size="large"
              name="shopping cart"
              aria-label="shopping cart icon"
            />
          </Transition>
        </div>
      )
    }
  }
}

const mapStateToProps = state => {
  return {
    cart: state.cart,
    cartAnimation: state.cartAnimation,
  }
}

export default connect(mapStateToProps)(CartIcon)
