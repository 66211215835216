import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import MiniCart from '../navigation/miniCart'

const ImageData = ({ data }) => (
    <MiniCart data={data}/>
)
    
export default props => (
  <StaticQuery
    query={graphql`
      query imageData {
        allImageSharp {
          edges {
            node {
              fluid(traceSVG: { color: "#BB133E" }) {
                aspectRatio
                tracedSVG
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
                originalImg
                originalName
                presentationWidth
                presentationHeight
              }
            }
          }
        }
        allSitePage(filter: { context: { title: { ne: null } } }) {
          edges {
            node {
              context {
                id
                title
                description
                quote1
              }
            }
          }
        }
      }
    `}
    render={data => <ImageData data={data} {...props} />}
  />
)
