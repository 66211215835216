import React, { Component } from 'react'
import { connect } from 'react-redux'
import Img from 'gatsby-image'

class MiniPic extends Component {
  
  render() {
    let images = this.props.children
    return <Img fluid={images.hatImage[this.props.color].node.fluid} />
  }
}

const mapStateToProps = state => {
  return {
    productColor: state.productColor,
  }
}

export default connect(mapStateToProps)(MiniPic)
