import React from 'react'
import { Link } from 'gatsby'
import { connect } from 'react-redux'
import {
  Button,
  Grid,
  Header,
  Label,
  Segment,
  Transition,
} from 'semantic-ui-react'
import MiniPic from '../product/miniPic'
import PicData from '../product/PicData'
import config from '../../config'

class MiniCart extends React.Component {
  componentDidMount = () => {
    this.props.dispatch({
      type: 'UPDATE_STATE',
      item: 'miniCartAnimation',
      value: false,
    })
  }

  hideMiniCart = () => {
    setTimeout(() => {
      this.props.dispatch({
        type: 'UPDATE_STATE',
        item: 'miniCartAnimation',
        value: false,
      })
    }, 2000)
  }

  lastItemAdded = () => {
    if (this.props.cart.length > 0) {
      let lastItemNumber = this.props.cart.length - 1
      return this.props.cart[lastItemNumber]
    } else {
      return {}
    }
  }
  showImage = item => {
    if (this.props.cart.length > 0) {
      return (
        <PicData data={this.props.data} name={this.lastItemAdded().name} color={this.lastItemAdded().color}>
          <MiniPic />
        </PicData>
      )
    } else {
      return null
    }
  }

  showColor = color => {
    if (color === '1') {
      return 'Blue'
    } else {
      return 'White'
    }
  }

  render() {
    let item = {}

    item = this.lastItemAdded()

    return (
      <Transition
        animation="slide down"
        visible={this.props.miniCartAnimation}
        onComplete={this.hideMiniCart}
      >
        <Segment
          style={{
            position: 'absolute',
            top: '10vh',
            right: '2vw',
            width: '400px',
            zIndex: 10,
          }}
        >
          <Grid style={{ padding: '5px' }}>
            <Grid.Row style={{ marginLeft: '5px' }}>
              <Grid.Column floated="left" width={8}>
                <Header as="h2">Added to Cart</Header>
              </Grid.Column>
              <Grid.Column floated="right" width={8}>
                <Link to="cart">
                  <Button
                    floated="right"
                    style={{
                      backgroundColor: config.colors.primary,
                      color: config.colors.tertiary,
                    }}
                  >
                    Go to my Cart
                  </Button>
                </Link>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row
              style={{
                border: '1px solid lightgrey',
                margin: '2px',
                padding: '2px',
              }}
            >
              <Grid.Column width={6}>{this.showImage(item)}</Grid.Column>
              <Grid.Column textAlign="right" width={10}>
                <Grid.Row>
                  <Header as="h3" className='redunderline'>{item.name}</Header>
                </Grid.Row>

                <Grid.Row>Color: {this.showColor(item.color)}</Grid.Row>
                <Grid.Row>Quantity: {item.qty}</Grid.Row>

                <Grid.Row>
                  <Label tag>$ {(item.price / 100).toFixed(2)}</Label>
                </Grid.Row>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row style={{ marginLeft: '5px' }}>
              <Grid.Column floated="left" width={10}>
                <Header as="h2">Total ({this.props.cart.length} items)</Header>
              </Grid.Column>
              <Grid.Column floated="right" textAlign="right" width={6}>
                <Label
                  size="large"
                  style={{
                    backgroundColor: config.colors.secondary,
                    color: config.colors.tertiary,
                  }}
                  tag
                >
                  $ {this.props.orderPrice}
                </Label>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      </Transition>
    )
  }
}

// export default MiniCart
const mapStateToProps = state => {
  return {
    cart: state.cart,
    miniCartAnimation: state.miniCartAnimation,
    orderPrice: state.orderPrice,
  }
}

export default connect(mapStateToProps)(MiniCart)
