import React from 'react'
import { Link } from 'gatsby'
import { Header } from 'semantic-ui-react'
import config from '../../config'

const Footer = () => (
  <div
    style={{
      marginTop: '2vh',
      marginLeft: '0px',
      marginRight: '0px',
      width: '100%',
      minHeight: '150px',
      height:'16vh',
      background: config.colors.primary,
      color: 'ffffff',
      padding: '15px',
      textAlign: "center"
    }}
    
  >
    {/* <Container fluid style={{margin: '1vh' }}>
      <a
        href="https://www.facebook.com/faftshirts"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="facebook link"
      >
        <Icon size="large" circular inverted color="pink" name="facebook" link alt="facebook icon"/>
      </a>
      <a
        href="https://www.instagram.com/funnyaftshirts/"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="instagram link"
      >
        <Icon size="large" circular inverted color="pink" name="instagram" link  alt="instagram icon"/>
      </a>
      <a
        href="https://twitter.com/AfTshirts"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="twitter link"
      >
        <Icon size="large" circular inverted color="pink" name="twitter" link alt="twitter icon" />
      </a>
    </Container> */}
    <div style={{ color: 'white', padding: '1vh', textAlign: 'center' }}>
      <Link style={{color: 'white', padding: '1vh'}} to="/aboutus">About Us</Link>
      <Link style={{color: 'white', padding: '1vh'}} to="/return-policy">Return Policy</Link>
      <Link style={{color: 'white', padding: '1vh'}} to="/contact-us">Contact Us</Link>
    </div>
    <div>
      <Header as="h4" style={{ color: 'white', padding: '1vh', textAlign: 'center' }}>
      &copy; Copyright MakeAmericaHatsAgain 2019
      </Header>
    </div>
  </div>
)

export default Footer
